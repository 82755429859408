<template>
    <div>
        <Quote/>
        <GoBack next="Kurs turini qo'shish"/>
        <div class="row justify-content-center">
            <div class="col-11 col-sm-10 col-md-8">
                <form id="course-type " @submit.prevent="editCourseType">
                    <div id="cours-type">
                        <input
                            id="exampleInputEmail1"
                            v-model="courseType.name"
                            class="form-control"
                            placeholder="Kurs turi"
                            required
                            type="text"
                        >
                    </div>

                    <div class="my-4">
                        <label class="form-label" for="category">Kategoriya turi</label>
                        <b-form-select
                            id="category"
                            v-model="courseType.category"
                            class="form-control form-control-select"
                            name="hero"
                        >
                            <option
                                v-for="category of getCategories"
                                :key="category.id"
                                :label="category.name"
                                :value="'/api/categories/' + category.id"
                            ></option>
                        </b-form-select>
                    </div>

                    <div class="d-flex justify-content-end mb-3">
                        <div class="row col-12 col-md-5 col-lg-4 col-xl-3">
                            <button-component>Saqlash</button-component>
                        </div>
                    </div>
                    <div>
                        <b-modal ref="modal-success" hide-footer title="Kadirov.Dev">
                            <div class="d-block text-center">
                                <h3>Kurs turi muvaffaqiyatli o'zgartirildi</h3>
                            </div>
                            <b-button
                                class="mt-3 float-end"
                                size="lg"
                                style="width: 100px"
                                variant="btn btn-primary"
                                @click="hideErrorModal"
                            >
                                OK
                            </b-button>
                        </b-modal>
                    </div>
                </form>

            </div>
        </div>
    </div>
</template>

<script>
import GoBack from "@/components/GoBack";
import Quote from "@/components/Quote";
import {mapActions, mapGetters} from "vuex";
import ButtonComponent from "@/components/ButtonComponent.vue";

export default {
    name: "ChangeCourseType",
    components: {ButtonComponent, GoBack, Quote},
    methods: {
        ...mapActions(['pushCourseType', 'fetchCourseType', 'putCourseType', 'fetchCategories']),
        editCourseType() {
            let data = {id: this.$route.params.id, name: this.courseType.name, category: this.courseType.category}
            this.putCourseType(data)
                .then(() => {
                    this.$refs['modal-success'].show()
                    setTimeout(this.hideErrorModal, 3000)
                })
                .catch(() => {
                    console.log('coursetype da xato')
                })
        },
        hideErrorModal() {
            this.$refs['modal-success'].hide()
            this.$router.push('/exist-course-types-list')
        }
    },
    computed: {
        ...mapGetters(['getCourseType', 'getCategories'])
    },
    data() {
        return {
            courseType: {
                name: "",
                category: '',
            },
        }
    },
    mounted() {
        this.fetchCourseType(this.$route.params.id)
            .then(() => {
                this.fetchCategories()
                this.courseType.name = this.getCourseType.name
                this.courseType.category = '/api/categories/' + this.getCourseType.category.id
            })
    }
}
</script>

<style scoped>

@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@300&display=swap');

* {
    padding: 0;
    margin: 0;
}

#exampleInputEmail1 {
    border-color: #8B2BB9;
    border-radius: 10px;
    padding: 5px;
}

.form-control:focus {
    box-shadow: 0px 0px 0px 3px rgba(139, 43, 185, 0.37);
    border-color: #8B2BB9;
}

#course-type {
    /* Auto Layout */
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-right: 0;
    width: auto;
    height: 174px;
    left: 259.5px;
    top: 111px;
}

#cours-type {
    left: 0;
    top: 0;
    /* Inside Auto Layout */
    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 30px 0px;
    font-family: 'Comfortaa', cursive;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 20px;
    /* identical to box height */
}

#cours-price {
    left: 0px;
    top: 68px;
    /* Inside Auto Layout */
    flex: none;
    order: 1;
    flex-grow: 0;
    margin: 30px 0px;
    font-family: 'Comfortaa', cursive;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 20px;
    /* identical to box height */
    background: #FFFFFF;
    border: 1px solid #80007F;
    box-sizing: border-box;
    border-radius: 10px;
}

.justify-content-end .btn-primary {
    text-align: center;
    padding: 5px 60px;
    border-radius: 10px;
    background-color: #80007F;
    font-family: 'Comfortaa', cursive;
    font-size: 16px;
}

.btn-primary {
    color: #fff;
    background-color: #80007F;
    border-color: #80007F;
}

.mb-3 .form-control {
    border: solid #80007F 1px;
    font-family: Comfortaa, cursive;
}

.form-control:hover {
    box-shadow: 0 0.5px 0.5px #FFFFFF inset, 0 0 5px #b505b4 !important;
}

.form-control {
    color: #000000;
    border-color: #8B2BB9;
    padding-left: 1.5rem;
    border-radius: 10px;
}

</style>
